body {
  background-color: #e4e4e4;
}

div {
  box-sizing: border-box;
}

.quiz {
  width: 975px;
  margin: 0 auto;
  margin-top: 100px;
}

.question {
  background-color: #0094da;
  color: white;
  padding: 10px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.results {
  background-color: #fff;
  height: 300px;
  text-align: center;
}

.congratulations {
  background-color: #0094da;
  padding: 20px;
  font-size: 36px;
  color: #fff;
}

.results-info {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 18px;
}

.answer {
  width: 48%;
  background: #fff;
  border: 2px solid #0094da;
  min-height: 70px;
  cursor: pointer;
  margin-bottom: 30px;
  display: flex;
}

.answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
}

.answer-letter {
  font-size: 30px;
  background-color: #0084da;
  color: #fff;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answer-text {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.correct-answer {
  border: 2px solid #1ea55b;
}

.correct-answer .answer-letter {
  background: #1ea55b;
}

.wrong-answer {
  border: 2px solid #dc0a0a;
}

.wrong-answer .answer-letter {
  background: #dc0a0a;
}

.next-button {
  margin: 0 auto;
  color: #fff;
  background: #0b0e21;
  width: 300px;
  font-size: 18px;
  font-weight: 600;
  padding: 14px 75px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}

.score {
  background: #fff;
  color: 000;
  font-size: 18px;
  width: 200px;
  margin: 0 auto;
  font-weight: 600;
  height: 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled-answer {
  pointer-events: none;
}
